import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";

const ChildSafetyPolicy = () => {
  const navigate = useHistory();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleClick = () => {
    navigate.push("/");
  };

  return (
    <div className="fixed z-50 overflow-y-auto inset-0 bg-black text-white py-8 px-2 lg:px-4 select-text">
      <FaTimes
        className="absolute z-30 right-6 top-8 cursor-pointer"
        onClick={handleClick}
      />

      <main
        className="mx-auto px-2 flex flex-col items-center"
        style={{ maxWidth: "2500px" }}
      >
        <Link to="/">
          <img
            src="/images/logo.png"
            className="block h-[80px] mt-6 cursor-pointer"
            alt="Logo"
          />
        </Link>

        <section className="flex flex-col gap-6 container">
          <h2 className="text-2xl font-bold text-azwaajacolor2 text-center mt-2 ">
            Azwaaja Child Safety and Prevention of Exploitation Policy
          </h2>
          <p className="text-center">
            At Azwaaja, we are committed to providing a safe, respectful, and
            secure environment for all users. We have a zero-tolerance policy
            toward child sexual abuse and exploitation (CSAE) and actively work
            to prevent, detect, and report any violations.
          </p>

          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold text-azwaajacolor2">
              1. Zero-Tolerance Policy
            </h3>
            <p>
              Azwaaja strictly prohibits any form of child exploitation, abuse,
              or inappropriate conduct involving minors. Any suspected CSAE
              violations will result in immediate account suspension and
              reporting to the relevant authorities.
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold text-azwaajacolor2">
              2. Moderation and Prevention Measures
            </h3>
            We have implemented several safeguards to prevent CSAE and ensure a
            safe community:
            <p className="ml-4">
              <strong>Age Verification:</strong> Users must meet the minimum age
              requirement to register. Any suspected underage accounts will be
              flagged and removed.
            </p>
            <p className="ml-4">
              <strong>Content Moderation:</strong> Our team actively monitors
              and reviews reports related to CSAE. AI-based detection and human
              moderation help identify and remove harmful content.
            </p>
            <p className="ml-4">
              <strong>Strict Community Guidelines:</strong> Any content or
              behavior violating our CSAE policy results in immediate
              enforcement actions, including bans and legal escalation.
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold text-azwaajacolor2">
              3. Reporting Mechanism
            </h3>
            We encourage our users to report any suspicious or inappropriate
            activity related to child safety through the following:
            <p className="ml-4">
              <strong>In-App Reporting Feature:</strong> Users can report
              concerns within the app.
            </p>
            <p className="ml-4">
              <strong>Email Reports:</strong> Users can email{" "}
              <a href="mailto:help@azwaaja.com" className="underline">
                help@azwaaja.com
              </a>{" "}
              to report any suspected CSAE activity.
            </p>
            <p className="ml-4">
              <strong>Priority Escalation:</strong> CSAE-related reports are
              prioritized and investigated immediately.
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold text-azwaajacolor2">
              4. Cooperation with Law Enforcement
            </h3>
            <p>
              Azwaaja fully cooperates with law enforcement agencies and child
              protection organizations to report and take action against
              CSAE-related activities.
            </p>
            <p>
              We:
              <ul className="list-disc ml-6">
                <li>Report suspected CSAE cases to relevant authorities.</li>
                <li>
                  Share necessary information with law enforcement, in
                  accordance with legal obligations.
                </li>
                <li>
                  Work with third-party child protection organizations to ensure
                  best practices in moderation and prevention.
                </li>
              </ul>
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold text-azwaajacolor2">
              5. Data Security and Privacy
            </h3>
            Protecting user data is a top priority, especially regarding minors.
            Azwaaja implements:
            <p className="ml-4">
              <strong>Encryption:</strong> All data is securely encrypted to
              prevent unauthorized access.
            </p>
            <p className="ml-4">
              <strong>Access Control:</strong> Only authorized personnel handle
              CSAE reports and investigations.
            </p>
            <p className="ml-4">
              <strong>Strict Privacy Policies:</strong> No data related to child
              safety reports is shared publicly.
            </p>
          </div>
        </section>

        <section className="self-center mx-auto flex items-center justify-center">
          <div
            style={{ borderBottom: "2px solid", borderRight: "2px solid" }}
            className="h-0 my-8 w-72 xl:w-[100vh] max-w-[523px] opacity-50"
          />
        </section>

        <div className="text-sm mt-12">
          <p className="text-center">
            For more details, please visit our{" "}
            <a
              href="https://www.azwaaja.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Privacy Policy
            </a>{" "}
            or contact our Safety Team at{" "}
            <a href="mailto:help@azwaaja.com" className="underline">
              help@azwaaja.com
            </a>
            .
          </p>
        </div>
      </main>
    </div>
  );
};

export default ChildSafetyPolicy;
